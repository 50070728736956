import { getOrCreateDataObject } from 'o365.vue.ts';

export default getOrCreateDataObject({
    id: 'dsOMegaMenu',
    viewName:'sviw_System_MyWebAppsWithMenuGroup',
    distinctRows: true,
    allowUpdate: false,
    allowInsert: false,
    allowDelete: false,
    appendData: false,
    disableLayouts: true,
    selectFirstRowOnLoad: true,
    fields: [
        {
            name:'ArticleID',
            type: 'string'
        },
        {
            name:'MenuGroup',
            type: 'string',
            sortOrder:1,
            sortDirection:'asc'
        },
        {
            name:'Title',
            type: 'string',
            sortOrder:2,
            sortDirection:'asc'
        },
        {
            name:'SearchColumn',
            type: 'string'
        },
        {
            name:'Keywords',
            type: 'string'
        },
        {
            name:'IsBookMarked',
            type: 'boolean'
        },
        {
            name:'IsNextGenApp',
            type: 'boolean'
        }
    ],
    clientSideHandler: false,
    maxRecords: -1,
    dynamicLoading: false,
    whereClause: 'MenuGroup IS NOT NULL AND IsMobile = 0 AND HideFromNav = 0'
});
